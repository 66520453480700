export const quotes = [
  {
    quote:
      "Everyone I’ve met through Cerebral has been more than helpful! I would say my life has improved 110% since starting with the service! Thank you!",
    name: "Joshua",
  },
  {
    quote:
      "I love that Cerebral is convenient and simple to use. Your care team is easy to contact and appointments are easily made on a simple interface.",
    name: "Sammie",
  },
  {
    quote:
      "I can not say enough about how much help I have had from everyone here. No one here treats you like a number they are concerned about you and want to see you grow.",
    name: "Michele S",
  },
]
